@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import 'font-awesome.min.css';
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,300i,400i");

/*
	Ethereal by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)',
		xxsmall: '(max-width: 360px)',
		short: '(min-aspect-ratio: 16/7)',
		xshort: '(min-aspect-ratio: 16/6)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border'
	));

// Mixins.

	@mixin gradient-background($tint: false, $opacity: 0.25, $stop1: 25%, $stop2: 50%, $angle: 45deg) {
		$background-image: (
			url('../../images/overlay.png'),
			linear-gradient($angle, transparentize(_palette(accent1), (1 - $opacity)) $stop1, transparentize(_palette(accent2), (1 - $opacity)) $stop2, transparentize(_palette(accent3), (1 - $opacity)))
		);

		$background-size: (
			128px 128px,
			auto
		);

		@if ($tint) {
			$x: linear-gradient(0deg, rgba(0,0,0,0.125), rgba(0,0,0,0.125));
			$y: auto;
			$background-image: append($background-image, $x);
			$background-size: append($background-size, $y);
		}

		background-image: $background-image;
		background-size: $background-size;
	}

	@mixin gradient-background-small($tint: false, $opacity: 0.25, $stop1: 25%, $stop2: 50%) {
		@include gradient-background($tint, $opacity, $stop1, $stop2, 135deg);
	}

	@mixin spans($x) {
		@for $i from 0 through 10 {

			@if ($i > 0) {
				.span-#{$i} {
					width: ($x * $i);
				}
			}

			@if ($i < 10) {
				.span-#{$i}-25 {
					width: ($x * $i) + ($x * 0.25);
				}

				.span-#{$i}-5 {
					width: ($x * $i) + ($x * 0.5);
				}

				.span-#{$i}-75 {
					width: ($x * $i) + ($x * 0.75);
				}
			}
		}
	}

	@mixin spans-small($x) {
		@for $i from 0 through 10 {

			@if ($i > 0) {
				.span-#{$i} {
					width: 100%;
				}
			}

			@if ($i < 10) {
				.span-#{$i}-25 {
					width: 100%;
				}

				.span-#{$i}-5 {
					width: 100%;
				}

				.span-#{$i}-75 {
					width: 100%;
				}
			}
		}
	}

// Base.

	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/table';
	@import 'components/button';
	@import 'components/gallery';
	@import 'components/panel';
	@import 'components/panel-banner';
	@import 'components/panel-spotlight';

// Layout.

	@import 'layout/page-wrapper';
	@import 'layout/wrapper';