///
/// Ethereal by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Typography */

	html {
		font-size: 18pt;
		font-size: 1vmax;

		@include breakpoint(xlarge) {
			font-size: 12pt;
			font-size: 1.1vmax;
		}

		@include breakpoint(large) {
			font-size: 11pt;
			font-size: 1.5vmax;
		}
	}

	body, input, select, textarea {
		color: _palette(fg);
		font-family: _font(family);
		font-size: 1rem;
		font-weight: _font(weight);
		line-height: 1.65;
	}

	a {
		@include vendor('transition', (
			'color #{_duration(transition)} ease-in-out',
			'border-bottom-color #{_duration(transition)} ease-in-out'
		));
		color: inherit;
		border-bottom: dotted 1px;
		text-decoration: none;

		&:hover {
			border-bottom-color: transparent;
			color: _palette(accent3);
		}
	}

	strong, b {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;

		body.is-ie & {
			width: 100%;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		color: _palette(fg-bold);
		font-family: _font(family-heading);
		font-weight: _font(weight-heading);
		line-height: 1.3;
		margin: 0 0 (_size(element-margin) * 0.5) 0;
		letter-spacing: -0.05em;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	h1, h2, h3 {
		&.major {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				width: 3.5rem;
				height: 0.1rem;
				background-color: _palette(border);
			}
		}
	}

	h1 {
		font-size: 3rem;
		line-height: 1.2;

		&.major {
			margin: 0 0 (_size(element-margin) * 1.75) 0;

			&:after {
				bottom: -1.325rem;
			}
		}
	}

	h2 {
		font-size: 1.75rem;
		line-height: 1.2;

		&.major {
			margin: 0 0 (_size(element-margin) * 1.325) 0;

			&:after {
				bottom: -1.2rem;
			}
		}
	}

	h3 {
		font-size: 1.325rem;

		&.major {
			margin: 0 0 (_size(element-margin) * 1.25) 0;

			&:after {
				bottom: -0.75rem;
			}
		}
	}

	h4 {
		font-size: 1rem;
	}

	h5 {
		font-size: 0.9rem;
	}

	h6 {
		font-size: 0.7rem;
	}

	sub {
		font-size: 0.8rem;
		position: relative;
		top: 0.5rem;
	}

	sup {
		font-size: 0.8rem;
		position: relative;
		top: -0.5rem;
	}

	blockquote {
		border-left: solid 0.25rem _palette(border);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4) _size(element-margin);
	}

	code {
		background: _palette(border-bg);
		border-radius: _size(border-radius);
		font-family: _font(family-fixed);
		font-size: 0.8rem;
		margin: 0 0.25rem;
		padding: 0.25rem 0.65rem;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.8rem;
		margin: 0 0 _size(element-margin) 0;
 		white-space: pre-wrap;

		code {
			display: block;
			line-height: 1.625;
			padding: 1rem 1.5rem;
			overflow-x: auto;
			margin: 0;
		}
	}

	hr {
		border: 0;
		border-bottom: solid 2px _palette(border);
		margin: (_size(element-margin) * 1.25) 0;
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

	@include breakpoint(small) {
		html {
			font-size: 12pt;
		}

		h1 {
			font-size: 2.25rem;
			line-height: 1.2;

			&.major {
				margin: 0 0 (_size(element-margin) * 1.75) 0;

				&:after {
					bottom: -1.325rem;
				}
			}
		}

		h2 {
			font-size: 1.5rem;
			line-height: 1.2;

			&.major {
				margin: 0 0 (_size(element-margin) * 1.325) 0;

				&:after {
					bottom: -1.2rem;
				}
			}
		}

		h3 {
			font-size: 1rem;

			&.major {
				margin: 0 0 (_size(element-margin) * 1.25) 0;

				&:after {
					bottom: -0.75rem;
				}
			}
		}

		h4 {
			font-size: 1rem;
		}

		h5 {
			font-size: 0.9rem;
		}

		h6 {
			font-size: 0.7rem;
		}

		h1, h2, h3, h4, h5, h6 {
			br {
				display: none;
			}
		}
	}

	@include breakpoint(xxsmall) {
		html {
			font-size: 11pt;
		}
	}