///
/// Ethereal by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Form */

	$gutter: (_size(element-margin) * 1);

	form {

		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		width: calc(100% + #{$gutter * 2});
		margin: ($gutter * -1) 0 _size(element-margin) ($gutter * -1);

		> .field {
			@include vendor('flex-grow', '0');
			@include vendor('flex-shrink', '0');
			padding: $gutter 0 0 $gutter;
			width: calc(100% - #{$gutter * 1});

			&.half {
				width: calc(50% - #{$gutter * 0.5});
			}

			&.third {
				width: calc(#{100% / 3} - #{$gutter * (1 / 3)});
			}

			&.quarter {
				width: calc(25% - #{$gutter * 0.25});
			}
		}

		> .actions {
			@include vendor('flex-grow', '0');
			@include vendor('flex-shrink', '1');
			margin: $gutter 0 0 $gutter;
		}
	}

	label {
		color: _palette(fg-bold);
		display: block;
		font-family: _font(family-heading);
		font-size: 0.8rem;
		font-weight: _font(weight-heading);
		margin: 0 0 (_size(element-margin) * 0.325) 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		background: transparent;
		border: solid 2px _palette(border);
		border-radius: _size(border-radius);
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 0.75rem;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}

		&:focus {
			border-color: _palette(accent3);
		}
	}

	option {
		background-color: _palette(fg-bold);
		color: _palette(bg);
	}

	.select-wrapper {
		@include icon;
		display: block;
		position: relative;

		&:before {
			display: block;
			content: '\f107';
			position: absolute;
			top: 0;
			right: 0;
			width: _size(element-height);
			height: _size(element-height);
			line-height: _size(element-height);
			color: _palette(fg);
			pointer-events: none;
			text-align: center;

			body.is-ie & {
				line-height: 2.5;
			}
		}

		select::-ms-expand {
			display: none;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: _size(element-height);
	}

	textarea {
		padding: 0.75rem 1rem;
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2rem;
		opacity: 0;
		width: 1rem;
		z-index: -1;

		& + label {
			@include icon;
			position: relative;
			color: _palette(fg);
			cursor: pointer;
			display: inline-block;
			font-size: 1rem;
			font-weight: _font(weight);
			margin-bottom: 0;
			padding-left: (_size(element-height) * 0.6) + 1rem;
			padding-right: 1rem;

			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				top: 0;
				left: 0;
				width: (_size(element-height) * 0.6);
				height: (_size(element-height) * 0.6);
				line-height: (_size(element-height) * 0.575);
				background: _palette(border-bg);
				border: solid 1px _palette(border);
				border-radius: _size(border-radius);
				color: _palette(bg);
				text-align: center;

				body.is-ie & {
					line-height: 1.5;
				}
			}
		}

		&:checked + label {
			&:before {
				content: '\f00c';
				background: _palette(fg-bold);
				border-color: _palette(fg-bold);
			}
		}

		&:focus + label {
			&:before {
				border-color: _palette(accent3);
				box-shadow: 0 0 0 1px _palette(accent3);
			}
		}

		&:focus:checked + label {
			&:before {
				background: _palette(accent3);
			}
		}

		&.color1 {
			& + label {
				&:before {
					color: _palette(accent1);
				}
			}
		}

		&.color2 {
			& + label {
				&:before {
					color: _palette(accent2);
				}
			}
		}

		&.color3 {
			& + label {
				&:before {
					color: _palette(accent3);
				}
			}
		}

		&.color4 {
			& + label {
				&:before {
					color: _palette(accent4);
				}
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: _size(border-radius);
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	::-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	.formerize-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	@include breakpoint(small) {
		form {
			margin: ($gutter * -1) 0 _size(element-margin) 0;
			width: 100%;

			> .field {
				padding: $gutter 0 0 0;
				width: 100% !important;
			}

			> .actions {
				margin: $gutter 0 0 0;
			}
		}
	}